import React from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {SectionNotification} from 'wix-ui-tpa/SectionNotification';
import {Error} from 'wix-ui-tpa/dist/src/assets/icons';
import {useTranslation, TranslationFunction} from 'yoshi-flow-editor-runtime';
import {useStores} from '../../../../StoresContext';
import s from './DeliveryError.scss';
import {ICart} from '../../../../../../../types/app.types';

const getErrorMessage = (t: TranslationFunction, cart: ICart, isZipCodeValid: boolean, isDigitalCart: boolean) => {
  if (!isZipCodeValid) {
    return t('cart.errors.invalid_zip_postal_code');
  }

  /* istanbul ignore next */
  if (cart.destination) {
    const destination = cart.destination.subdivisionName || cart.destination.countryName;
    const message = isDigitalCart ? 'cart.errors.cant_sell_to_country' : 'cart.errors.cant_ship_to_country';
    return t(message, {country: destination});
  }

  /* istanbul ignore next */
  return isDigitalCart ? t('cart.errors.cant_sell_to_your_country') : t('cart.errors.cant_ship_to_your_country');
};

export const DeliveryError = () => {
  const {t} = useTranslation();
  const {isZipCodeValid} = useStores().orderStore;
  const {isDigitalCart, cart} = useStores().cartStore;

  return (
    <div data-hook={OrderSummaryDataHook.DeliveryErrorRoot}>
      <dl>
        <dt>
          <SectionNotification type="error" data-hook={OrderSummaryDataHook.DeliveryErrorMessage} className={s.root}>
            <SectionNotification.Icon icon={<Error />} />
            <SectionNotification.Text>
              {getErrorMessage(t, cart, isZipCodeValid, isDigitalCart)}
            </SectionNotification.Text>
          </SectionNotification>
        </dt>
      </dl>
    </div>
  );
};
