import {useStores} from '../components/cart/Widget/StoresContext';
import {useEffect, useRef} from 'react';
import {IControllerProps} from '../types/app.types';

export interface ResultProp<T> {
  counter?: number;
  value?: T;
}

type OnlyFunctionProps<O> = {[K in keyof O]: O[K] extends Function ? K : never}[keyof O];
type Promisify<T> = T extends Promise<any> ? T : Promise<T>;

export type PromisifyFunctionReturnType<T extends (...args: any) => any> = (
  ...args: Parameters<T>
) => Promisify<ReturnType<T>>;

export function useFunctionResultObservation() {
  const stores = useStores();
  const ref = useRef<Record<string, {listeners: Function[]; prevValue: ResultProp<any>}>>();

  function getPromiseResolveFunction(): [Promise<unknown>, Function] {
    let resolve;

    const promise = new Promise((r) => {
      resolve = r;
    });
    return [promise, resolve];
  }

  if (!ref.current) {
    ref.current = {};
  }

  const waitForResult = <ResultType>(fnName: string): Promisify<ResultType> => {
    const [promise, resolve] = getPromiseResolveFunction();
    if (!ref.current[fnName]) {
      ref.current[fnName] = {
        listeners: [],
        prevValue: {},
      };
    }
    ref.current[fnName].listeners.push(resolve);
    return promise as Promisify<ResultType>;
  };

  const withObservation = <T extends IControllerProps[keyof IControllerProps], M extends OnlyFunctionProps<T>>(
    obj: T,
    method: M
  ): T[M] => {
    return ((...args: Parameters<T[M]>): Promisify<ReturnType<T[M]>> => {
      obj[method](args);
      return waitForResult<ReturnType<T[M]>>(method as string);
    }) as T[M];
  };

  useEffect(() => {
    Object.keys(ref.current).forEach((fn) => {
      // istanbul ignore else: still no test where the result isn't available on the next props update
      if (ref.current[fn].prevValue?.counter !== ((stores as any)[`${fn}Result`] as ResultProp<any>)?.counter) {
        const result = ((stores as any)[`${fn}Result`] as ResultProp<any>).value;
        ref.current[fn].listeners.forEach((listener) => {
          listener(result);
        });
      }

      ref.current[fn].prevValue = (stores as any)[`${fn}Result`];
    });
  });

  return {waitForResult, withObservation};
}
