import React from 'react';
import {Button} from 'wix-ui-tpa/Button';
import {classes} from './CheckoutButton.st.css';
import {ReactComponent as Lock} from './Lock.svg';
import classnames from 'classnames';
import {useStores} from '../../../StoresContext';
import settingsParams from '../../../../settingsParams';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';

export enum CheckoutButtonDataHook {
  button = 'CheckoutButtonDataHook.button',
}

export interface CheckoutButtonProps {
  disabled?: boolean;
}

export function CheckoutButton({disabled}: CheckoutButtonProps) {
  const {get: getSettings} = useSettings();
  const accessibilityEnabled = useStores().host.accessibilityEnabled;
  const {navigateToCheckout} = useStores().navigationStore;

  return (
    <Button
      className={classnames(classes.checkoutButton)}
      data-hook={CheckoutButtonDataHook.button}
      disabled={disabled}
      onClick={() => navigateToCheckout({accessibilityEnabled})}
      upgrade>
      <Lock key={''} />
      <span className={classes.checkoutButtonLabel}>{getSettings(settingsParams.CART_BUTTON_TEXT)}</span>
    </Button>
  );
}
