import React from 'react';
import s from './CartScreenReaderMessage.scss';
import {useStores} from '../../../StoresContext';

export enum ScreenReaderMessageDataHook {
  Root = 'ScreenReaderMessage.Root',
}

export const CartScreenReaderMessage = () => {
  const {screenReaderMessage} = useStores().cartStore;

  return (
    <div
      className={s.root}
      data-hook={ScreenReaderMessageDataHook.Root}
      aria-live="polite"
      aria-relevant="additions text"
      aria-atomic="false">
      {screenReaderMessage}
    </div>
  );
};
