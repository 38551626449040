import React, {useEffect} from 'react';
import cx from 'classnames';
import s from './CartApp.scss';
import {CartBody} from './partials/CartBody';
import {EmptyState} from '../Components/EmptyState/EmptyState';
import {useStores} from '../StoresContext';
import {withResizeObserver} from './HOC/withResizeObserver';
import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {CART_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';

export enum CartAppDataHook {
  root = 'CartAppDataHook.root',
}

const CartApp = () => {
  const {isResponsive} = useStores();
  const {shouldRenderEmptyState} = useStores().cartStore;
  const {handlePaymentMethodError} = useStores().cashierExpressStore;
  const viewerVersion = isResponsive ? 'viewer_x' : 'viewer_classic';
  const classNames = cx(s.main, s[viewerVersion]);

  useEffect(() => {
    handlePaymentMethodError();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={s.container}>
      <main data-hook={CartAppDataHook.root} className={classNames}>
        {shouldRenderEmptyState ? <EmptyState /> : <CartBody />}
      </main>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(withResizeObserver(CartApp), {
  dsn: CART_DSN,
  config: {environment: 'Native Component'},
});
