/* eslint-disable sonarjs/no-small-switch */
import React from 'react';
import {WidgetProps} from 'yoshi-flow-editor-runtime';
import {IControllerProps} from '../../../types/app.types';
import CartApp from './CartApp/CartApp';
import {GeneralError} from './Components/GeneralError/GeneralError';
import {Loader} from './Components/Loader/Loader';
import {StoresContext} from './StoresContext';

const SSRSpinner = () => (
  <div style={{height: '400px'}}>
    <Loader />
  </div>
);

const CartAppRoot = (controllerProps: WidgetProps<IControllerProps>) => {
  switch (true) {
    // todo: remove when SSR cache is disabled
    case controllerProps.isWarmupSpinner:
      return <SSRSpinner />;
    case controllerProps.generalError:
      return <GeneralError />;
    default:
      return (
        <StoresContext value={controllerProps}>
          <CartApp host={controllerProps.host} ravenUserContextOverrides={{}} />
        </StoresContext>
      );
  }
};

export default CartAppRoot;
