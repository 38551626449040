import React from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {useStores} from '../../../../StoresContext';
import {ShippingDestination} from '../ShippingDestination/ShippingDestination';
import {useTranslation} from 'yoshi-flow-editor-runtime';

const ShippingDestinationAndShippingRates = () => {
  const {t} = useTranslation();
  const {shouldDisplayDeliveryError} = useStores().orderStore;
  const {cart} = useStores().cartStore;

  const displayPrice = !shouldDisplayDeliveryError;

  return (
    <dl>
      <dt>
        <div data-hook={OrderSummaryDataHook.TotalShippingShippingDestinationWithTitle}>
          <span data-hook={OrderSummaryDataHook.TotalShippingTitle}>{t('cart.estimated_shipping')}</span>
          &nbsp;
          <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationRoot} />
        </div>
      </dt>
      {displayPrice && (
        <dd data-hook={OrderSummaryDataHook.TotalShippingEstimatedShipping}>
          {cart.convertedTotals.shipping > 0 ? cart.convertedTotals.formattedShipping : t('cart.free_shipping')}
        </dd>
      )}
    </dl>
  );
};

export const TotalShipping = () => {
  const {isEstimatedDestinationIsShippable} = useStores().orderStore;

  return (
    <div data-hook={OrderSummaryDataHook.TotalShippingRoot}>
      {isEstimatedDestinationIsShippable ? (
        <ShippingDestinationAndShippingRates />
      ) : (
        <dt>
          <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationRoot} />
        </dt>
      )}
    </div>
  );
};
