import React from 'react';
import settingsParams from '../../../settingsParams';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import s from './Headline.scss';
import {HTMLTag} from '../HTMLTag/HTMLTag';
import {ContinueShopping, ContinueShoppingPosition} from '../ContinueShopping/ContinueShopping';
import {useStores} from '../../StoresContext';
import {Caret} from '../Icons/Caret';

export enum HeadlineDataHook {
  root = 'HeadlineDataHook.root',
}

const RenderContinueShopping = () => {
  return (
    <div className={s.continueShopping}>
      <ContinueShopping position={ContinueShoppingPosition.headline} />
      <Caret className={s.caret} />
    </div>
  );
};

const RenderTitle = ({title}: {title: string}) => {
  return (
    <HTMLTag type="h2" className={s.title} data-hook={HeadlineDataHook.root}>
      {title}
    </HTMLTag>
  );
};

export function Headline() {
  const {get: getSettings} = useSettings();
  const {shouldRenderEmptyState} = useStores().cartStore;
  const {shouldRenderContinueShopping} = useStores().navigationStore;
  const withContinueShopping = shouldRenderContinueShopping && !shouldRenderEmptyState;

  return (
    <div className={s.headline}>
      <RenderTitle title={getSettings(settingsParams.CART_TITLE) as string} />

      {withContinueShopping && <RenderContinueShopping />}
    </div>
  );
}
