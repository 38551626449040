import React from 'react';
import {CheckoutButton} from './CheckoutButton/CheckoutButton';
import {CashierExpressButtons} from '../CashierExpressButtons/CashierExpressButtons';
import {useStores} from '../../StoresContext';
import {PaypalButton} from './PaypalButton/PaypalButton';
import s from './CheckoutButtons.scss';
import {useEnvironment} from 'yoshi-flow-editor-runtime';

export enum CheckoutButtonsDataHook {
  Default = 'CheckoutButtons.default',
  Top = 'CheckoutButtons.topView',
}

interface CheckoutButtonsProps {
  isOnTop?: boolean;
}

export function CheckoutButtons(props: CheckoutButtonsProps) {
  const {shouldShowDynamicPaymentOptions} = useStores().cashierExpressStore;
  const {isSSR} = useEnvironment();
  const {isCheckoutRegularFlowEnabled, isCheckoutPayPalFastFlowEnabled} = useStores().cartStore;
  const {isOnTop} = props;
  const dataHook = isOnTop ? CheckoutButtonsDataHook.Top : CheckoutButtonsDataHook.Default;
  const {isCheckoutButtonDisabled} = useStores().navigationStore;

  return (
    <div data-hook={dataHook} className={s.checkoutButtons}>
      {isCheckoutRegularFlowEnabled && <CheckoutButton disabled={isCheckoutButtonDisabled} />}
      {shouldShowDynamicPaymentOptions
        ? !isSSR && <CashierExpressButtons />
        : isCheckoutPayPalFastFlowEnabled && <PaypalButton />}
    </div>
  );
}
